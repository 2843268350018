<template>
  <div class="container mx-auto h-full w-full py-4">
    <app-modal-payment-link-detail
      :show="showModalDetail"
      @close="showModalDetail = false"
      :payment_link="selectPaymentLink"
      @update:payment_link="selectPaymentLink"
    />
    <app-table
      :apiResponse="apiPaginationResponse.data ?? []"
      :loading="loading"
      :filters="[
        {
          key: 'filter[title]',
          type: 'text',
          placeholder: $t('payment.link.filter.title'),
          value: '',
        },
        {
          key: 'filter[email]',
          type: 'text',
          placeholder: $t('payment.link.filter.email'),
          value: '',
        },
        {
          key: 'filter[currency]',
          type: 'text',
          placeholder: $t('payment.link.filter.currency'),
          value: '',
        },
        {
          key: 'filter[amount]',
          type: 'number',
          placeholder: $t('payment.link.filter.amount'),
          value: '',
        },
        {
          key: 'filter[payment_link_id]',
          type: 'text',
          placeholder: $t('payment.link.filter.payment_link_id'),
          value: '',
        },
        {
          key: 'filter[attempt_id]',
          type: 'text',
          placeholder: $t('payment.link.filter.payment_attempt_id'),
          value: '',
        },
        {
          key: 'filter[settlement_id]',
          type: 'text',
          placeholder: $t('payment.link.filter.settlement_id'),
          value: '',
        },
        {
          key: 'filter[has_status]',
          type: 'select',
          placeholder: $t('payment.link.filter.payment_status'),
          value: {},
          items: [
            {
              id: 'paid',
              name: 'Paid',
            },
            {
              id: 'unpaid',
              name: 'Unpaid',
            },
            {
              id: 'paid&processed',
              name: 'Paid & Processed',
            },
          ],
        },
        {
          key: 'filter[created_at_between]',
          type: 'date',
          value: '',
        },
      ]"
      @params-changed="paramsChanged"
      @on-item-click="onItemClick"
      :showEmptyButton="false"
    >
      <template v-slot:title>
        <div class="flex space-x-3">
          <app-icon-outline
            @click="goToBack"
            name="ArrowLeftIcon"
            class="w-5 cursor-pointer"
          />
          <p class="font-medium">
            {{ $t("payment.open-payment-link.detail-page.title") }}
          </p>
        </div>
      </template>
      <template v-slot:header>
        <th class="font-bold">{{ $t("payment.link.header.amount") }}</th>
        <th class="font-bold">&nbsp;</th>
        <th class="font-bold">{{ $t("payment.link.header.title") }}</th>
        <th class="font-bold">{{ $t("payment.link.header.email") }}</th>
        <th class="font-bold">{{ $t("payment.link.header.date") }}</th>
        <th class="font-bold">
          {{ $t("payment.link.header.payment_link") }}
        </th>
      </template>
      <template v-slot:body="slotData">
        <td>
          <p class="font-medium">
            {{
              $formats.currency(
                slotData.model.currency,
                slotData.model.amount ?? 0.0
              )
            }}
          </p>
        </td>
        <td class="flex place-items-center space-x-2 justify-between">
          <app-badge
            :status="slotData.model.paid ? 'success' : 'light'"
            class="w-16 text-xs"
          >
            {{
              slotData.model.paid
                ? $t("payment.detail.paid")
                : $t("payment.detail.unpaid")
            }}
          </app-badge>
          <app-badge
            v-if="slotData.model.settlement != null"
            :status="'success'"
            class="w-16 text-xs"
          >
            {{ $t("payment.detail.processed") }}
          </app-badge>
        </td>
        <td>
          {{ slotData.model.title }}
        </td>
        <td>
          {{ slotData.model.email }}
        </td>
        <td>
          {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
        </td>
        <td>
          <div class="flex flex-row space-x-1">
            <button @click.stop="copyUrl(slotData.model)">
              <div
                class="
                  px-2
                  py-1
                  border
                  rounded-md
                  flex flex-row
                  justify-center
                  bg-background
                  place-items-center
                  hover:bg-gray-100
                "
              >
                <app-icon
                  name="ClipboardCopyIcon"
                  class="h-5 w-5 text-gray-400"
                />
                <p class="ml-1 text-xs">
                  {{ $t("payment.detail.copy_link") }}
                </p>
              </div>
            </button>

            <button @click.stop="resendEmail(slotData.model)">
              <div
                class="
                  px-2
                  py-1
                  border
                  rounded-md
                  flex flex-row
                  justify-center
                  bg-background
                  place-items-center
                  hover:bg-gray-100
                "
              >
                <app-icon
                  name="PaperAirplaneIcon"
                  class="h-5 w-5 text-gray-400"
                />

                <p class="ml-1 text-xs">
                  {{ $t("payment.detail.resend_email") }}
                </p>
              </div>
            </button>
          </div>
        </td>
      </template>
    </app-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filters: "",
      showModalDetail: false,
      selectPaymentLink: null,
    };
  },

  async mounted() {
    await this.retrievePaymentLinks();

    if (this.$route.params.open_payment_link_id) {
      this.findOpenPaymentLinkById(this.$route.params.open_payment_link_id);
    }

    if (this.$route.query.id) {
      this.showModalPaymentLinkById(this.$route.query.id);
    }
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["openPaymentLinkStore/apiPaginationResponse"];
    },
    openPaymentLink() {
      return this.$store.getters["openPaymentLinkStore/open_payment_link"];
    },
    dataEmpty() {
      return (
        this.$lodash.isEmpty(this.apiPaginationResponse?.data?.data) ?? null
      );
    },
    business() {
      return this.$store.getters["businessStore/business"];
    },
    loading() {
      return this.$store.getters["openPaymentLinkStore/loading"];
    },
    selectedRole() {
      return this.$store.getters["authStore/selectedRole"];
    },
    errors() {
      return this.$store.getters["openPaymentLinkStore/errors"];
    },
  },

  methods: {
    goToBack() {
      this.$router.go(-1);
    },
    async retrievePaymentLinks() {
      return this.$store.dispatch(
        "openPaymentLinkStore/retrieveOpenPaymentLinksPaymentLinks",
        {
          open_payment_link_id: this.$route.params.open_payment_link_id,
          filters: this.filters,
        }
      );
    },

    findOpenPaymentLinkById(open_payment_link_id) {
      this.$store.dispatch(
        "openPaymentLinkStore/findOpenPaymentLink",
        open_payment_link_id
      );
    },

    async showModalPaymentLinkById(id) {
      let payment_link = this.apiPaginationResponse?.data?.data?.find(
        (payment_link) => payment_link._id == id
      );

      if (this.$lodash.isEmpty(payment_link)) {
        let result = await this.$store.dispatch("paymentStore/getPaymentLink", {
          payment_link_id: id,
        });
        payment_link = result?.data;
      }

      if (!this.$lodash.isEmpty(payment_link)) {
        this.selectPaymentLink = payment_link;
        this.showModalDetail = true;
      }
    },
    copyUrl(model) {
      const clipboardData =
        event.clipboardData ||
        window.clipboardData ||
        event.originalEvent?.clipboardData ||
        navigator.clipboard;

      clipboardData.writeText(model.payment_url);

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.copy_link"),
        },
        5000
      );
    },

    async resendEmail(model) {
      const resendEmail = await this.$store.dispatch(
        "paymentStore/resendEmail",
        model._id
      );

      if (resendEmail == null) return;

      this.$notify(
        {
          group: "success",
          title: this.$t("payment.link.resend"),
        },
        5000
      );
    },

    paramsChanged(filters, queryParams) {
      this.filters = queryParams;
      this.retrievePaymentLinks();
    },

    filterFormatDate(key) {
      let date = this.filters["filter[" + key + "]"];

      let start = date?.start_date;
      let end = date?.end_date;

      if (date) {
        this.filters["filter[" + key + "]"] = start + "," + end;
      }
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectPaymentLink = data;
    },
  },
};
</script>